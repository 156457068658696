.terminal, .terminal *, .cmd, .cmd *, .cmd textarea {
  all: revert;
}

.terminal .terminal-output .format, .cmd .format, .cmd-prompt, .cmd-prompt div {
  display: inline-block;
}

.terminal h1, .terminal h2, .terminal h3, .terminal h4, .terminal h5, .terminal h6, .terminal pre, .cmd {
  margin: 0;
}

.cmd .cmd-clipboard {
  let: -16px;
  width: 16px;
  height: 16px;
  left: calc(-16px / var(--pixel-density, 1)) !important;
  width: calc(16px / var(--pixel-density, 1)) !important;
  height: calc(16px / var(--pixel-density, 1)) !important;
  color: #0000 !important;
  resize: none !important;
  z-index: 1000 !important;
  white-space: pre !important;
  text-indent: -9999em !important;
  top: 0 !important;
  top: calc(var(--cursor-line, 0) * var(--size, 1) * (14px / var(--pixel-density, 1)) ) !important;
  background: none !important;
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cmd span.cmd-end-line, .cmd span.cmd-end-line span, .cmd div.cmd-end-line span[data-text]:last-child, .cmd div.cmd-end-line span[data-text]:last-child span, .cmd textarea, .cmd .cursor + span:empty {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.terminal img, .terminal audio, .terminal object, .terminal canvas {
  cursor: default;
}

.terminal {
  line-height: initial;
  --base-background: var(--background, #000);
  --base-color: var(--color);
  contain: content;
  position: relative;
}

.terminal-scroller {
  scrollbar-gutter: stable;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
}

terminal.terminal-temp {
  visibility: hidden;
}

body.terminal {
  min-height: 100vh;
  height: 100%;
}

html {
  height: 100%;
}

body.terminal, body.full-screen-terminal {
  height: 100%;
  margin: 0;
}

body.full-screen-terminal .terminal {
  height: 100%;
}

.terminal > div.terminal-fill {
  min-height: 100%;
  height: 100%;
}

.terminal > .terminal-pixel {
  width: 1px;
  height: 1px;
  display: inline;
  position: absolute;
  top: -2px;
  left: -2px;
}

.terminal > .terminal-resizer, .terminal > .terminal-font .terminal-resizer {
  pointer-events: none !important;
  z-index: -1 !important;
  height: 100% !important;
  width: 100% !important;
  border: none !important;
  padding: 0 !important;
  position: absolute !important;
  inset: 0 !important;
  overflow: hidden !important;
}

.cmd {
  width: 100%;
  z-index: 300;
  padding: 0;
  position: relative;
}

.terminal .cmd, .terminal .cmd div {
  background: none !important;
}

.terminal a[tabindex="1000"], .terminal a[tabindex="1000"]:active, .terminal a[tabindex="1000"]:focus {
  outline: none;
}

.terminal .inverted, .cmd.cmd.cmd .inverted, .terminal .terminal-inverted, .cmd.cmd.cmd .cmd-inverted {
  color: #000 !important;
  background-color: #aaa !important;
}

.terminal .terminal-output > :not(.raw) a[href], .cmd a[href], .terminal.external a[href] {
  color: #37f;
  color: var(--link-color, #37f);
  cursor: pointer;
}

.cmd a[href]:not(.terminal-inverted), .terminal .terminal-output > :not(.raw) a[href]:not(.terminal-inverted), .terminal.external a[href]:not(.terminal-inverted) {
  --color: var(--link-color, #37f);
  text-shadow: 0 0 calc(var(--glow) * 5px) var(--color, #ccc);
}

.terminal .terminal-output > :not(.raw) a[href].terminal-inverted, .terminal.external a[href].terminal-inverted {
  background: var(--color, #ccc);
  text-shadow: 0 0 calc(var(--glow) * 5px) var(--background, #000);
}

.terminal .terminal-output > :not(.raw) a[href]:hover, .cmd a[href]:hover, .terminal.external a[href]:hover {
  color: #000;
  background-color: #37f;
  text-decoration: none;
  background-color: var(--link-color, #37f) !important;
  color: var(--base-background, #000) !important;
}

.terminal .terminal-output > :not(.raw) a[href] span, .cmd a[href] span, .terminal.external a[href] span {
  --color: var(--link-color, #37f);
  text-decoration: underline;
  color: #37f !important;
  color: var(--link-color, #37f) !important;
}

.terminal .terminal-output > :not(.raw) a[href]:hover span, .cmd a[href]:hover span, .terminal.external a[href]:hover span {
  text-decoration: none;
  background-color: #37f !important;
  background-color: var(--link-color, #37f) !important;
  color: #000 !important;
  color: var(--background, #000) !important;
}

.cmd .cmd-cursor, .cmd .cmd-cursor-line > span, .cmd .cmd-cursor-line img {
  display: inline-block;
}

.cmd .cmd-cursor.cmd-blink > span[data-text]:not(.emoji):not(.fa):not(.far):not(.fas) span, .cmd .cmd-cursor.cmd-blink a, .cmd .cmd-cursor.cmd-blink .fa, .cmd .cmd-cursor.cmd-blink .far, .cmd .cmd-cursor.cmd-blink .fas, .cmd .cmd-cursor .emoji {
  -ms-animation: terminal-blink 1s infinite linear;
  animation: 1s linear infinite terminal-blink;
}

.bar.terminal .inverted, .bar.cmd .cmd-inverted {
  box-shadow: -2px 0 0 -1px #aaa;
  box-shadow: -2px 0 0 -1px var(--original-color, #aaa);
}

.terminal, .terminal .terminal-output > div > div, .cmd .cmd-prompt {
  height: auto;
  display: block;
}

.terminal .terminal-output > div:not(.raw) div {
  white-space: nowrap;
  clear: both;
}

.cmd .cmd-prompt > span, .cmd .cmd-prompt:empty {
  float: left;
}

.cmd [data-text] span, .terminal [data-text] span {
  display: inline-block;
}

.terminal-ouput span[style*="width"] {
  min-height: 14px;
  min-height: calc(var(--size, 1) * (14px / var(--pixel-density, 1)) );
}

.terminal .terminal-output > :not(.raw) > div, .cmd div, .terminal.external div {
  line-height: 1em;
  line-height: calc((var(--size) * (16px / var(--pixel-density, 1)) )  + (1px / var(--pixel-density, 1)) );
}

.cmd .cmd-prompt span.fa:before, .cmd .cmd-prompt span.fab:before, .cmd .cmd-prompt span.fad:before, .cmd .cmd-prompt span.fal:before, .cmd .cmd-prompt span.far:before, .cmd .cmd-prompt span.fas {
  position: relative;
  top: 2px;
}

.terminal, .terminal-output > :not(.raw), .terminal-output > :not(.raw) span:not(.fas):not(.far):not(.fa), .terminal-output > :not(.raw) a, .cmd, .cmd span:not(.fas):not(.far):not(.fa) {
  font-family: monospace;
  font-family: var(--font, monospace);
}

.terminal, .cmd {
  font-size: 12px;
}

.terminal-output > div:not(.raw) div:before, .cmd .cmd-line:before {
  content: "​";
  width: 0;
  display: inline-block;
}

.terminal span[data-text], .cmd span[data-text] {
  display: inline-block;
}

.terminal, terminal-output > div:not(.raw) div > span:not(.token):not(.inverted):not(.terminal-inverted):not(.cmd-inverted):not(.terminal-error):not(.emoji), .cmd span[data-text]:not(.cmd-inverted):not(.token):not(.emoji) {
  color: #aaa;
  background-color: #000;
}

.terminal span[data-text] span, .cmd span[data-text] span {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.terminal .ansi > div {
  line-height: 13px !important;
  line-height: calc(var(--size, 1) * (13px / var(--pixel-density, 1)) ) !important;
}

.cmd span.cmd-prompt, .cmd .cmd-prompt span {
  background-color: #0000 !important;
}

.terminal-output .emoji, .cmd .emoji {
  height: 12px;
  height: calc(12px / var(--pixel-density, 1));
  color: #0000;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.terminal .terminal-output .fa span, .terminal .terminal-output .fas span, .terminal .terminal-output .far span, .cmd .fa span, .cmd .fas span, .cmd .far span {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
  color: #0000 !important;
  background: none !important;
}

.terminal-output .emoji, .terminal-output .emoji span, .cmd .emoji, .cmd .emoji span {
  width: 2ch;
  display: inline-block;
}

.terminal, .cmd {
  box-sizing: border-box;
}

.cmd .cmd-cursor span:not(.token):not(.inverted) {
  color: inherit;
  background-color: inherit;
}

.cmd .emoji.emoji.emoji.emoji, .cmd .emoji.emoji.emoji.emoji span {
  color: #0000;
  background-color: #0000;
}

.cmd .cmd-cursor * {
  background-color: #0000;
}

.terminal span[style*="width"] span, .cmd span[style*="width"] span {
  width: inherit;
}

.cmd div {
  clear: both;
}

.cmd .cmd-prompt + div {
  clear: right;
}

terminal .terminal-output > div {
  margin-top: -1px;
}

.terminal-output > div.raw > div * {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.terminal .terminal-font {
  float: left;
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 1px;
  position: absolute;
  top: -100%;
  left: 0;
}

.cmd > span:not(.cmd-prompt) {
  float: left;
}

.cmd .cmd-prompt span.cmd-line {
  float: none;
  display: block;
}

.terminal table {
  border-collapse: collapse;
}

.terminal td {
  border: 1px solid #aaa;
}

.cmd span[data-text]:not(.emoji):not(.fa):not(.fas):not(.far) span {
  background-color: inherit;
  color: inherit;
}

.cmd [role="presentation"].cmd-cursor-line {
  z-index: 100;
  cursor: text;
  position: relative;
}

.cmd .cmd-prompt {
  z-index: 200;
  position: relative;
}

.cmd [role="presentation"]:not(.cmd-cursor-line) {
  overflow: hidden;
}

.cmd {
  --original-color: var(--color, #aaa);
}

.cmd a[href] {
  --original-color: var(--link-color, #37f);
}

@-webkit-keyframes terminal-blink {
  0%, 50% {
    background-color: #aaa;
    background-color: var(--original-color, #aaa);
    color: #000;
    color: var(--background, #000);
    -webkit-box-shadow: 0 0 calc(var(--glow) * 3px) var(--color, #aaa);
    box-shadow: 0 0 calc(var(--glow) * 3px) var(--color, #aaa);
  }

  50.1%, 100% {
    background-color: inherit;
    color: inherit;
    color: var(--original-background, var(--original-color, #aaa));
  }
}

@-moz-keyframes terminal-blink {
  0%, 50% {
    background-color: #aaa;
    background-color: var(--original-color, #aaa);
    color: #000;
    color: var(--background, #000);
    -webkit-box-shadow: 0 0 calc(var(--glow) * 3px) var(--color, #aaa);
    box-shadow: 0 0 calc(var(--glow) * 3px) var(--color, #aaa);
  }

  50.1%, 100% {
    background-color: inherit;
    color: inherit;
    color: var(--original-background, var(--original-color, #aaa));
  }
}

@keyframes terminal-blink {
  0%, 50% {
    background-color: #aaa;
    background-color: var(--original-color, #aaa);
    color: #000;
    color: var(--background, #000);
    -webkit-box-shadow: 0 0 calc(var(--glow) * 3px) var(--color, #aaa);
    box-shadow: 0 0 calc(var(--glow) * 3px) var(--color, #aaa);
  }

  50.1%, 100% {
    background-color: inherit;
    color: inherit;
    color: var(--original-background, var(--original-color, #aaa));
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@-webkit-keyframes terminal-glow {
  0%, 50% {
    background-color: #aaa;
    background-color: var(--original-color, #aaa);
    color: #000;
    color: var(--background, #000);
    box-shadow: 0 0 3px #aaa;
    -webkit-box-shadow: 0 0 3px var(--color, #aaa);
    box-shadow: 0 0 3px var(--color, #aaa);
  }

  50.1%, 100% {
    background-color: inherit;
    color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@-moz-keyframes terminal-glow {
  0%, 50% {
    background-color: #aaa;
    background-color: var(--original-color, #aaa);
    color: #000;
    color: var(--background, #000);
    box-shadow: 0 0 3px #aaa;
    -moz-box-shadow: 0 0 3px var(--color, #aaa);
    box-shadow: 0 0 3px var(--color, #aaa);
  }

  50.1%, 100% {
    background-color: inherit;
    color: inherit;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

@keyframes terminal-glow {
  0%, 50% {
    background-color: #aaa;
    background-color: var(--original-color, #aaa);
    color: #000;
    color: var(--background, #000);
    box-shadow: 0 0 3px #aaa;
    box-shadow: 0 0 3px var(--color, #aaa);
  }

  50.1%, 100% {
    background-color: inherit;
    color: inherit;
    box-shadow: none;
  }
}

@keyframes terminal-bar {
  0%, 50% {
    box-shadow: -2px 0 0 -1px #aaa;
    box-shadow: -2px 0 0 -1px var(--original-color, #aaa);
  }

  50.1%, 100% {
    box-shadow: none;
  }
}

@keyframes terminal-underline {
  0%, 50% {
    box-shadow: 0 2px #aaa;
    box-shadow: 0 2px 0 var(--original-color, #aaa);
  }

  50.1%, 100% {
    box-shadow: none;
  }
}

@keyframes terminal-none {
  0%, 100% {
    background-color: #aaa;
    background-color: var(--original-color, #aaa);
    color: #000;
    color: var(--background, #000);
    -webkit-box-shadow: 0 0 calc(var(--glow) * 3px) var(--color, #aaa);
    box-shadow: 0 0 calc(var(--glow) * 3px) var(--color, #aaa);
  }
}

.underline-animation .cmd .cmd-cursor.cmd-blink > span[data-text]:not(.emoji):not(.fa):not(.far):not(.fas) span, .underline-animation .cmd .cmd-cursor.cmd-blink a, .underline-animation .cmd .cmd-cursor.cmd-blink .fa, .underline-animation .cmd .cmd-cursor.cmd-blink .far, .underline-animation .cmd .cmd-cursor.cmd-blink .fas, .underline-animation .cmd .cmd-cursor .emoji {
  -ms-animation-name: terminal-underline;
  animation-name: terminal-underline;
}

.glow-animation .cmd .cmd-cursor.cmd-blink > span[data-text]:not(.emoji):not(.fa):not(.far):not(.fas) span, .glow-animation .cmd .cmd-cursor.cmd-blink a, .glow-animation .cmd .cmd-cursor.cmd-blink .fa, .glow-animation .cmd .cmd-cursor.cmd-blink .far, .glow-animation .cmd .cmd-cursor.cmd-blink .fas, .glow-animation .cmd .cmd-cursor .emoji {
  -ms-animation-name: terminal-glow;
  animation-name: terminal-glow;
}

.bar-animation .cmd .cmd-cursor.cmd-blink > span[data-text]:not(.emoji):not(.fa):not(.far):not(.fas) span, .bar-animation .cmd .cmd-cursor.cmd-blink a, .bar-animation .cmd .cmd-cursor.cmd-blink .fa, .bar-animation .cmd .cmd-cursor.cmd-blink .far, .bar-animation .cmd .cmd-cursor.cmd-blink .fas, .bar-animation .cmd .cmd-cursor .emoji {
  -ms-animation-name: terminal-bar;
  animation-name: terminal-bar;
}

@supports (-ms-ime-align:auto) {
  .cmd .cmd-clipboard {
    margin-left: -9999px;
  }

  @keyframes terminal-blink {
    0%, 50% {
      background-color: var(--original-color, #aaa);
      color: var(--background, #000);
    }

    50.1%, 100% {
      background-color: var(--background, #000);
      color: var(--original-color, #aaa);
    }
  }

  @keyframes terminal-bar {
    0%, 50% {
      border-left-color: var(--color, #aaa);
    }

    50.1%, 100% {
      border-left-color: var(--background, #000);
    }
  }

  @keyframes terminal-underline {
    0%, 50% {
      border-bottom-color: var(--color, #aaa);
      line-height: 12px;
      line-height: calc(var(--size, 1) * (12px / var(--pixel-density, 1)) );
    }

    50.1%, 100% {
      border-bottom-color: var(--background, #000);
      line-height: 12px;
      line-height: calc(var(--size, 1) * (12px / var(--pixel-density, 1)) );
    }
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cmd .cmd-clipboard {
    margin-left: -9999px;
  }

  .underline-animation .cursor.blink span span {
    margin-top: 1px;
  }

  
}

.terminal h1::-moz-selection, .terminal h2::-moz-selection, .terminal h3::-moz-selection, .terminal h4::-moz-selection, .terminal h5::-moz-selection, .terminal h6::-moz-selection, .terminal pre::-moz-selection, .terminal td::-moz-selection, .terminal .terminal-output::-moz-selection, .terminal .terminal-output div div::-moz-selection, .terminal .terminal-output div div a::-moz-selection, .terminal .terminal-output span[data-text]::-moz-selection, .terminal .terminal-output span[data-text]:not(.far):not(.fa):not(.fas) span::-moz-selection, .terminal .terminal-output .raw div::-moz-selection, .cmd span[data-text]::-moz-selection, .cmd span[data-text]:not(.far):not(.fa):not(.fas) span::-moz-selection {
  color: #000;
  background-color: #aaa;
}

.terminal .cmd {
  pointer-events: none;
}

.terminal .cmd-prompt * {
  pointer-events: visible;
}

.cmd-prompt > span:first-child {
  margin-left: var(--prompt-offset);
}

.terminal .cmd-wrapper > div:not(.cmd-cursor-line), .terminal .cmd-cursor-line > span, .terminal .cmd textarea {
  pointer-events: visible;
}

.terminal h1::selection, .terminal h2::selection, .terminal h3::selection, .terminal h4::selection, .terminal h5::selection, .terminal h6::selection, .terminal pre::selection, .terminal td::selection, .terminal .terminal-output::selection, .terminal .terminal-output div div::selection, .terminal .terminal-output div div a::selection, .terminal .terminal-output span[data-text]::selection, .terminal .terminal-output span[data-text]:not(.far):not(.fa):not(.fas) span::selection, .terminal .terminal-output .raw div::selection, .cmd span[data-text]:not(.far):not(.fa):not(.fas) span::selection {
  color: #000;
  background-color: #aaaaaafc;
}

.terminal-output .emoji::-moz-selection, .terminal-output .emoji span::-moz-selection, .cmd .emoji::-moz-selection, .cmd .emoji span::-moz-selection, .cmd textarea::-moz-selection {
  color: #0000 !important;
  background-color: #0000 !important;
}

.terminal-output .emoji::selection, .terminal-output .emoji span::selection, .cmd .emoji span::selection, .cmd .emoji::selection, .cmd textarea::selection {
  color: #0000 !important;
  background-color: #0000 !important;
}

.terminal .terminal-output > :not(.raw) .terminal-error, .terminal .terminal-output > :not(.raw) .terminal-error * {
  color: red;
  color: var(--error-color, red);
}

.tilda {
  width: 100%;
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
}

.ui-dialog-content .terminal {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.ui-dialog .ui-dialog-content.dterm {
  padding: 0;
}

.clear {
  clear: both;
}

.terminal .terminal-fill {
  width: 100%;
  height: 100%;
  opacity: .01;
  pointer-events: none;
  box-sizing: border-box;
  border: none;
  margin: 1px 0 0;
  position: absolute;
  top: -100%;
  left: 0;
}

.terminal-scroller, .terminal .terminal-fill, .cmd-editable {
  padding: calc(10px / var(--pixel-density, 1));
}

.cmd-editable {
  padding-top: 0;
}

.terminal-scroller {
  padding-bottom: 0;
}

.terminal .terminal-output > :not(.raw) .terminal-error, .terminal .terminal-output > :not(.raw) .terminal-error * {
  --color: var(--error-color, red);
  text-shadow: 0 0 calc(var(--glow) * 5px) var(--color);
}

.terminal .terminal-output > :not(.raw) a[href] {
  text-shadow: 0 0 calc(var(--glow) * 5px) var(--color);
}

.terminal .cmd {
  margin-bottom: calc(10px / var(--pixel-density, 1));
  position: relative;
}

.terminal .partial, .terminal .partial > div {
  display: inline-block;
}

@supports (--css: variables) {
  .terminal, .terminal-output > :not(.raw) span[data-text]:not(.token):not(.inverted):not(.terminal-inverted):not(.cmd-inverted):not(.terminal-error):not(.emoji), .terminal-output > :not(.raw) a, .terminal-output > :not(.raw) div, .cmd, .cmd span[data-text]:not(.cmd-inverted):not(.token):not(.emoji), .terminal.external [data-text], .cmd div {
    color: var(--color, #aaa);
    background-color: var(--background, #000);
  }

  .terminal [data-text], .cmd [data-text] {
    text-shadow: var(--text-shadow, 0 0 calc(var(--glow) * 5px) var(--color, #ccc));
  }

  .terminal span[style*="--length"] {
    width: calc(var(--length, 1) * var(--char-width, 7.23438) * 1px);
    display: inline-block;
  }

  .terminal, .terminal-output > :not(.raw) span, .terminal-output > :not(.raw) a, .terminal-output > :not(.raw) div, .cmd, .cmd span, .cmd div {
    font-size: calc(var(--size, 1) * (12px / var(--pixel-density, 1)) );
  }

  .terminal-output .emoji, .cmd .emoji {
    height: calc(var(--size, 1) * (12px / var(--pixel-density, 1)) );
  }

  .cmd .clipboard {
    top: calc(var(--size, 1) * (14 / var(--pixel-density, 1)) * var(--cursor-line, 0) * 1px);
  }

  .terminal .inverted, .cmd.cmd.cmd .inverted, .cmd.cmd.cmd .cmd-inverted {
    background-color: var(--color, #aaa) !important;
    color: var(--background, #000) !important;
  }

  .cmd .cmd-cursor.cmd-blink {
    color: var(--color, #aaa);
    background-color: var(--background, #000);
  }

  .cmd .cmd-cursor.cmd-blink > span[data-text]:not(.emoji):not(.fa):not(.far):not(.fas) span, .cmd .cmd-cursor.cmd-blink a, .cmd .cmd-cursor.cmd-blink .fa, .cmd .cmd-cursor.cmd-blink .far, .cmd .cmd-cursor.cmd-blink .fas, .cmd .cmd-cursor.cmd-blink .emoji {
    -webkit-animation: var(--animation, terminal-blink) 1s infinite linear;
    -moz-animation: var(--animation, terminal-blink) 1s infinite linear;
    -ms-animation: var(--animation, terminal-blink) 1s infinite linear;
    animation: var(--animation, terminal-blink) 1s infinite linear;
    --original-background: inherit;
  }

  .cmd .cmd-cursor.cmd-blink .emoji span {
    color: #0000;
    background: none;
  }

  .terminal h1::-moz-selection, .terminal h2::-moz-selection, .terminal h3::-moz-selection, .terminal h4::-moz-selection, .terminal h5::-moz-selection, .terminal h6::-moz-selection, .terminal pre::-moz-selection, .terminal td::-moz-selection, .terminal .terminal-output::-moz-selection, .terminal .terminal-output div div::-moz-selection, .terminal .terminal-output div div a::-moz-selection, .terminal .terminal-output span[data-text]::-moz-selection, .terminal .terminal-output span[data-text]:not(.far):not(.fa):not(.fas):not(.emoji) span::-moz-selection, .terminal .terminal-output .raw div::-moz-selection, .cmd span[data-text]:not(.far):not(.fa):not(.fas):not(.emoji) span::-moz-selection {
    background-color: var(--color, #aaa);
    color: var(--background, #000);
  }

  .terminal .terminal-output div div a::-moz-selection {
    background-color: var(--link-color, #0f60fffc) !important;
    color: var(--background, #000) !important;
  }

  .terminal .terminal-output div div a:hover::-moz-selection {
    background-color: var(--link-color, #023290fc) !important;
  }

  .terminal h1::selection, .terminal h2::selection, .terminal h3::selection, .terminal h4::selection, .terminal h5::selection, .terminal h6::selection, .terminal pre::selection, .terminal td::selection, .terminal .terminal-output::selection, .terminal .terminal-output div div::selection, .terminal .terminal-output div div a::selection, .terminal .terminal-output span[data-text]:not(.emoji)::selection, .terminal .terminal-output span[data-text]:not(.far):not(.fa):not(.fas):not(.emoji) span::selection, .terminal .terminal-output .raw div::selection, .cmd span[data-text]:not(.far):not(.fa):not(.fas):not(.emoji) span::selection {
    background-color: var(--color, #aaaaaafc) !important;
    color: var(--background, #000) !important;
  }

  .terminal .terminal-output div div a::selection {
    background-color: var(--link-color, #0f60fffc) !important;
    color: var(--background, #000) !important;
  }

  .terminal .terminal-output div div a:hover::selection {
    background-color: var(--link-color, #023290fc) !important;
  }
}

@property --color {
  syntax: "<color>";
  inherits: true;
  initial-value: #aaaaaafc;
}

@property --background {
  syntax: "<color>";
  inherits: true;
  initial-value: #000;
}

@property --link-color {
  syntax: "<color>";
  inherits: true;
  initial-value: #37f;
}

@property --size {
  syntax: "<number>";
  inherits: true;
  initial-value: 1;
}

@supports (-ms-ime-align:auto) {
  .terminal h1::selection, .terminal h2::selection, .terminal h3::selection, .terminal h4::selection, .terminal h5::selection, .terminal h6::selection, .terminal pre::selection, .terminal td::selection, .terminal .terminal-output div div::selection, .terminal .terminal-output div div a::selection, .cmd span[data-text]::selection {
    color: #000;
    background-color: #aaaaaafc;
  }
}

.terminal .token.token, .terminal .token.operator, .terminal .token.entity, .terminal .token.variable, .terminal .token.url, .terminal .token.string, .terminal .style .token.string, .terminal .token.token, .cmd .token.operator, .cmd .token.entity, .cmd .token.variable, .cmd .token.url, .cmd .token.string, .cmd .style .token.string, .cmd .token.token {
  background-color: inherit;
}

.cmd .cursor-wrapper ul {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 14px;
  left: 0;
}

.cmd .cursor-wrapper li {
  cursor: pointer;
  white-space: nowrap;
}

.cmd .cursor-wrapper li:hover {
  color: #000;
  background: #aaa;
}

.cursor-wrapper {
  position: relative;
}

.terminal-output img {
  vertical-align: top;
}

.cmd img {
  height: 14px;
  height: calc(var(--size, 1) * (14px / var(--pixel-density, 1)) );
  border: 1px solid #0000;
}

.cmd-cursor img {
  border-color: #ccc;
  border-color: var(--color, #ccc);
}

.terminal-output svg.terminal-broken-image {
  height: calc(var(--size, 1) * (14px / var(--pixel-density, 1)) );
}

.terminal-output svg.terminal-broken-image use {
  fill: var(--color, #ccc);
}

.terminal-error {
  --color: var(--error-color);
}

.terminal-glow {
  --animation: terminal-glow;
}

.terminal-glow span[data-text], .terminal-glow .cmd-prompt > span, .terminal-glow a[data-text], .terminal-glow [data-text] span, .terminal-glow .terminal-output > div span, .terminal-glow .terminal-output > div a[href] {
  text-shadow: 1px 1px 5px #ccc;
  text-shadow: 1px 1px 5px var(--color, #ccc);
}

.terminal-scroll-marker {
  height: 1px;
  height: calc(1px / var(--pixel-density, 1));
  margin-top: -1px;
  margin-top: calc(-1px / var(--pixel-density, 1));
  z-index: 100;
  position: relative;
}

.terminal-scroll-marker div {
  z-index: 200;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.terminal-less {
  touch-action: none;
  overscroll-behavior-y: contain;
}

.terminal-mobile.terminal-less .terminal-wrapper {
  pointer-events: none;
}

.terminal-mobile.terminal-less .terminal-output a, .cmd-editable {
  pointer-events: visible;
}

.cmd-editable:before {
  content: attr(data-cmd-prompt);
  display: inline-block;
}

.cmd-editable {
  top: 0;
  top: calc(var(--terminal-y, var(--cmd-y, 0))  + var(--terminal-scroll, 0) * 1px);
  bottom: 0;
  left: 0;
  right: 0;
  bottom: calc(var(--terminal-scroll, 0) * -1px);
  z-index: 500;
  color: #0000;
  opacity: .01;
  background: none;
  position: absolute;
}

.terminal-scroller::-webkit-scrollbar {
  width: calc(6px / var(--pixel-density, 1));
  height: calc(6px / var(--pixel-density, 1));
  background: var(--background, #000);
}

.terminal-scroller::-webkit-scrollbar-thumb {
  background: var(--color, #aaa);
}

.terminal-scroller::-webkit-scrollbar-thumb:hover {
  background: var(--color, #aaa);
}

.terminal-scroller {
  scrollbar-color: #aaa #000;
  scrollbar-color: var(--color, #aaa) var(--background, #000);
  scrollbar-width: thin;
}

.terminal-wrapper {
  --color: var(--base-color);
  --background: var(--base-background);
}

.cmd .token {
  --original-color: var(--color);
}

.terminal .terminal-blink, .cmd .terminal-blink {
  animation: 1s steps(2, start) infinite terminal-ansi-blink;
}

.terminal .terminal-output, .terminal .terminal-output *, .terminal .cmd * {
  --background: transparent;
}

.terminal .cmd::selection, .terminal .cmd ::selection, .terminal .terminal-output::selection, .terminal .terminal-output ::selection {
  --background: var(--base-background);
}

@keyframes terminal-ansi-blink {
  to {
    color: var(--background);
  }
}

@media (prefers-reduced-motion) {
  :root {
    --animation: terminal-none;
  }
}

/*# sourceMappingURL=index.52c5009d.css.map */
